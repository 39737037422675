.verification h1 {
  text-align: center;
  font-size: 3rem;
}

.verification p {
  text-align: center;
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;
}

.verification-success {
  color: green;
}

.verification-success a {
  color: rgb(199, 30, 32);
  text-decoration: none;
  cursor: pointer;
}

.verification-success a:hover {
  color: rgb(233, 50, 30);
  text-decoration: none;
  cursor: pointer;
}

.verification-error {
  color: red;
}

.verification-error a {
  color: rgb(199, 30, 32);
  text-decoration: none;
  cursor: pointer;
}

.verification-error a:hover {
  color: rgb(233, 50, 30);
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .verification h1 {
    font-size: 2.5rem;
  }

  .verification p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .verification h1 {
    font-size: 2rem;
  }

  .verification p {
    font-size: 1rem;
  }
}
